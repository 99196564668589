<template>
  <div class="layout-horizontal">
    <ys-infinite-scroll-view class="margin-right-small layout-inflexible"
                             style="background-color: #f1f1f1; width: 340px; height: 100%; box-sizing: border-box;">
      <div class="padding">
        <div class="card padding padding-horizontal-large">
          <calendar ref="calendar" @change="handleDateChange" :date-marks="[]"></calendar>
        </div>
        <div class="card padding padding-horizontal-large margin-top">
          <div class="layout-horizontal">
            <div class="font-weight-bold">用户打卡</div>
            <div class="layout-flexible"></div>
            <div class="btn-edit" @click="clickEdit">
              <block v-if="!isEdit"><span class="far fa-edit"></span> 编辑</block>
              <block v-else><span class="fas fa-check"></span> 完成</block>
            </div>
          </div>
          <div class="layout-horizontal layout-wrap padding-bottom margin-top-large"
               v-if="startedPunchInstanceList.length > 0 || isEdit">
            <div
                class="layout-vertical layout-center punch-instance"
                v-for="punchInstance in startedPunchInstanceList"
                :key="punchInstance.id"
                @click="clickPunchInstance(punchInstance)">
              <div class="font-align-right layout-self-stretch"
                   style="height: 0px; position: relative; top: -4px; z-index: 3" v-if="isEdit">
                <span class="fas fa-times-circle font-color-danger"></span>
              </div>
              <el-image
                  class="icon-punch"
                  fit="cover"
                  :src="$prepareImageUrl(punchInstance.punch.image)"></el-image>
              <div class="font-nowrap font-align-center padding-horizontal" style="width: 100%;">
                {{ punchInstance.punch.title }}
              </div>
            </div>
            <div class="layout-vertical layout-center punch-instance" v-if="isEdit"
                 @click="clickAdd">
              <div class="icon-punch layout-vertical layout-center layout-middle">
                +
              </div>
              <div class="font-nowrap font-align-center padding-horizontal" style="width: 100%">添加打卡</div>
            </div>
            <div class="layout-vertical layout-center punch-instance" v-else
                 @click="clickAllPunch">
              <div class="icon-punch layout-vertical layout-center layout-middle font-color-placeholder">
                ALL
              </div>
              <div class="font-nowrap font-align-center padding-horizontal" style="width: 100%">全部</div>
            </div>
          </div>
          <div v-else
               class="padding-vertical-large font-align-center font-color-light-placeholder font-size-extra-large">
            无打卡
          </div>
          <div class="btn-collapse font-color-placeholder font-size-medium"
               @click="clickCollapse">
            <span class="fas fa-chevron-circle-down" v-if="isCollapsed"></span>
            <span class="fas fa-chevron-circle-up" v-else></span>
            已停止
          </div>
          <el-collapse-transition>
            <div v-if="!isCollapsed">
              <div class="layout-horizontal layout-wrap padding-bottom margin-top-large"
                   v-if="stoppedPunchInstanceList.length > 0">
                <div
                    class="layout-vertical layout-center punch-instance"
                    v-for="punchInstance in stoppedPunchInstanceList"
                    :key="punchInstance.id"
                    style="opacity: .5"
                    @click="clickPunchInstance(punchInstance)">
                  <el-image
                      class="icon-punch"
                      fit="cover"
                      :src="$prepareImageUrl(punchInstance.punch.image)"></el-image>
                  <div class="font-nowrap font-align-center padding-horizontal font-size-extra-small" style="width: 100%;">
                    {{ punchInstance.punch.title }}
                  </div>
                </div>
              </div>
              <div v-else class="font-size-extra-large font-color-light-placeholder padding-vertical font-align-center">
                无已停止的打卡
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </ys-infinite-scroll-view>
    <punch-record-list-panel
        ref="punchRecordList"
        :user-id="userId"
        :organization-id="organizationId"
        :punch-instance-id="punchInstanceId"
        class="layout-flexible"
        style="max-width: 380px; box-shadow: 0 0 8px #dadada;"
    ></punch-record-list-panel>
    <div class="gap"></div>
    <punch-picker-dialog
        :organization-id="organizationId"
        :visible.sync="punchPickerDialogVisible" title="开始打卡"
        :default-selected-ids="defaultSelectedPunchIds"
        @pick="handlePunchPick"
    ></punch-picker-dialog>
    <delete-dialog :visible.sync="stopPunchDialogVisible" title="停止打卡"
                   :message="`您确定要停止：${currentPunchInstance ? currentPunchInstance.punch.title : null}吗？`"
                   @confirm="confirmStopPunch"></delete-dialog>
  </div>
</template>

<script>
import Calendar from "@/components/calendar/Calendar";
import PunchRecordListPanel from "@/pages/punch_record/panel/PunchRecordListPanel";
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import PunchPickerDialog from "@/components/dialog/PunchPickerDialog";
import DeleteDialog from "@/components/dialog/DeleteDialog";

export default {
  name: "PunchPanel",
  mixins: [httpapi],
  components: {DeleteDialog, PunchPickerDialog, YsInfiniteScrollView, PunchRecordListPanel, Calendar},
  props: {
    userId: Number,
    organizationId: Number,
    punchInstanceId: Number,
  },
  data() {
    return {

      /**
       * 正在执行的打卡
       */
      startedPunchInstanceList: [],

      /**
       * 已停止的打卡
       */
      stoppedPunchInstanceList: [],

      /**
       * 收起已停止的打卡
       */
      isCollapsed: true,

      /**
       * 编辑打卡
       */
      isEdit: false,

      /**
       * 开始打卡
       */
      defaultSelectedPunchIds: [],
      punchPickerDialogVisible: false,

      /**
       * 停止打卡
       */
      currentPunchInstance: null,
      stopPunchDialogVisible: false,
    }
  },
  computed: {
    inputParams: function () {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler: function () {
        this.loadingCode++;
        this.isEdit = false;
        this.isCollapsed = true;
        this.startedPunchInstanceList = [];
        this.stoppedPunchInstanceList = [];
        this.punchInstanceId = null;
        this.loadStartedPunchInstanceList();
      },
      immediate: true,
    }
  },
  mounted() {
    this.$refs.calendar.setSimpleState(true, false);
  },
  methods: {
    clickEdit: function () {
      this.isEdit = !this.isEdit;
    },
    clickCollapse: function () {
      this.isCollapsed = !this.isCollapsed;
      if (!this.isCollapsed) {
        this.loadStoppedPunchInstanceList();
      }
    },
    clickPunchInstance: function (punchInstance) {
      if (!this.isEdit) {
        this.punchInstanceId = punchInstance.id;
      } else {
        this.currentPunchInstance = punchInstance;
        this.stopPunchDialogVisible = true;
      }
    },
    clickAllPunch: function () {
      this.punchInstanceId = null;
    },
    clickAdd: function () {
      let defaultSeledctedPunchIds = this.startedPunchInstanceList.map((punchInstance) => punchInstance.punch.id);
      this.defaultSelectedPunchIds = defaultSeledctedPunchIds;
      this.punchPickerDialogVisible = true;
    },
    handlePunchPick: function (punchList) {
      this.startPunch(punchList[0]);
    },
    handleDateChange: function (date) {
      this.$refs.punchRecordList.date = date;
    },
    confirmStopPunch: function () {
      this.stopPunch(this.currentPunchInstance);
    },
    loadStartedPunchInstanceList: function () {
      this.$reqGet({
        path: `/punch/instance/list/started/${this.organizationId}/${this.userId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.startedPunchInstanceList = res.data;
          })
          .catch(() => {
            this.$message('加载失败');
          })
    },
    loadStoppedPunchInstanceList: function () {
      this.$reqGet({
        path: `/punch/instance/list/stopped/${this.organizationId}/${this.userId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.stoppedPunchInstanceList = res.data;
          })
          .catch(() => {
            this.$message('加载失败');
          })
    },
    startPunch: function (punch) {
      this.$reqPost({
        path: `/punch/instance/${this.organizationId}/${punch.id}/${this.userId}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.loadStartedPunchInstanceList();
            for (let n = this.stoppedPunchInstanceList.length - 1; n > 0; n--) {
              if (this.stoppedPunchInstanceList[n].punch.id === punch.id) {
                this.stoppedPunchInstanceList.splice(n, 1);
              }
            }
          })
          .catch(() => {
            this.$message('操作失败');
          })
    },
    stopPunch: function (punchInstance) {
      this.$reqDelete({
        path: `/punch/instance/${punchInstance.id}`
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.loadStoppedPunchInstanceList();
            for (let n = this.startedPunchInstanceList.length - 1; n >= 0; n--) {
              if (this.startedPunchInstanceList[n].id === punchInstance.id) {
                this.startedPunchInstanceList.splice(n, 1);
                break;
              }
            }
            if (this.currentPunchInstance === punchInstance.id) {
              this.currentPunchInstance = null;
            }
          })
          .catch(() => {
            this.$message.error('操作失败');
          })
    }

  }
}
</script>

<style scoped>

.gap {
  background-color: #f1f1f1;
  width: 10px;
}

.punch-instance {
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  width: 56px;
  overflow: hidden;
  margin: 8px 4px;
}

.punch-instance:hover {
  background-color: #f1f1f1;
}

.icon-punch {
  width: 32px;
  height: 32px;
  background-color: #f1f1f1;
  border-radius: 50%;
  overflow: hidden;
}

.btn-edit {
  cursor: pointer;
  user-select: none;
}

.btn-edit:hover {
  color: #409EFF;
}

.btn-collapse {
  cursor: pointer;
  user-select: none;
}

</style>