<template>
  <div class="punch-record layout-vertical" v-if="punchRecord">
    <div v-if="punchRecord.moment.message" class="punch-record__message"
         style="white-space: pre-wrap;">
      {{ punchRecord.moment.message }}
    </div>
    <div class="image-list layout-horizontal margin-top-small" v-if="punchRecord.moment.images.length > 0">
      <div class="image-container" v-for="(image, index) in punchRecord.moment.images" :key="image.id"
           v-show="index < 3"
           @click="handleImageClick(index)">
        <el-image class="image" :src="$prepareImageUrl(image)" fit="cover"></el-image>
        <div class="image-mask__container" v-if="punchRecord.moment.images.length > 3 && index === 2">
          <div class="image-mask layout-horizontal layout-middle layout-center font-color-light">
            <span>+{{ punchRecord.moment.images.length - 2 }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="punchRecord.appendix"
         class="layout-horizontal layout-wrap padding-vertical">
      <div
          class="appendix-item"
          v-for="appendixItem in punchRecord.appendix.selections"
      >
        {{ appendixItem }}
      </div>
    </div>
    <div class="layout-self-right" style="margin-top: -10px;">
      <div class="comment layout-self-left margin-top">
        <el-tooltip content="点赞" :open-delay="1000">
          <span class="btn-thumbs-up"
                :class="{'font-color-danger': punchRecord.commentInfo && punchRecord.commentInfo.thumbUpAdded}"
                @click="handleThumbUpClick">
            <i class="far fa-thumbs-up"></i>
            <span
                v-if="punchRecord.commentInfo && punchRecord.commentInfo.thumbUpCount > 0">{{
                punchRecord.commentInfo.thumbUpCount
              }}</span>
          </span>
        </el-tooltip>
        <el-tooltip content="点评" :open-delay="1000" @click.native="handleCommentClick">
          <span class="margin-left btn-comment">
           <i class="far fa-comment"></i>
          </span>
        </el-tooltip>
      </div>
    </div>
    <div v-for="comment in punchRecord.commentList" :key="comment.id" @click="handleCommentClick">
      <comment :comment="comment" @delete="handleCommentDeleteClick"></comment>
    </div>
    <ys-button
        size="small"
        type="secondary"
        class="layout-self-center margin-top"
        v-if="punchRecord.commentList.length < punchRecord.commentCount"
        @click="handleMoreCommentClick"
    >
      更多点评({{ punchRecord.commentCount }})
    </ys-button>
    <el-collapse-transition>
      <div v-if="commentInputVisible" class="layout-vertical">
        <ys-textarea v-model="commentMessageInputted" ref="commentInput" placeholder="点评"
                     @keyup.enter.native="addComment" @blur="addComment"
                     class="margin-top">
        </ys-textarea>
        <ys-button size="small" :type="commentMessageInputted ? 'primary' : 'secondary'"
                   class="margin-top-small layout-self-right" lighting
                   @click="addComment">发送
        </ys-button>
      </div>
    </el-collapse-transition>
    <ys-image-preview :visible.sync="previewImageVisible" :image-list="punchRecord.moment.images"
                      :current-image-index="0"></ys-image-preview>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import YsImagePreview from "@/components/wedigets/YsImagePreviewer";
import YsTextarea from "@/components/wedigets/YsTextarea";
import YsButton from "@/components/wedigets/YsButton";
import TextUtils from "@/assets/javascript/text-utils";
import Comment from "@/components/comment/Comment";

function doAddComment(message) {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;

  this.$reqPost({
    path: `/v1/punch/record/comment/add`,
    data: {
      recordId: this.punchRecord.id,
      message,
      organizationId: this.organizationId,
    }
  }).then(() => {
    this.commentMessageInputted = null;
    this.$message.success('已点评');
    this.busyLoadingCode = 0;
    this.punchRecord.commentCount++;
    doLoadCommentListByPunchRecord.bind(this)();
  }).catch(() => {
    if (this.loadingCode !== loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadCommentListByPunchRecord() {
  if (this.loadingCode === this.busyLoadingCode) {
    return
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: `/v1/punch/record/comment/list/get/after`,
    data: {
      recordId: this.punchRecord.id,
      id: this.punchRecord.commentList.length > 0 ? this.punchRecord.commentList[this.punchRecord.commentList.length - 1].id : null,
      count: 20,
    }
  }).then(res => {
    if (this.loadingCode !== loadingCode) return;
    this.$appendAfter(this.punchRecord.commentList, res.data);
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
    if (this.commentInputVisible == true) {
      this.$emit('show:comment', {target: this});
    }
  }).catch(() => {
    if (this.loadingCode !== loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (this.loadingCode !== loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doDeleteComment(comment) {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: '/comment/remove',
    data: {
      id: comment.id,
      commentId: comment.commentId,
    }
  }).then(() => {
    if (this.loadingCode !== loadingCode) return;
    this.punchRecord.commentCount--;
    for (let n = this.punchRecord.commentList.length - 1; n >= 0; n--) {
      if (this.punchRecord.commentList[n].id === comment.id) {
        this.punchRecord.commentList.splice(n, 1);
        break;
      }
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (this.loadingCode !== loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (this.loadingCode !== loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadCommentInfo() {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqGet({
    path: `/v1/punch/record/comment/info/get`,
    data: {
      recordId: this.punchRecord.id,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.punchRecord.commentInfo = res.data;
    this.punchRecord.commentCount = res.data.commentCount;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doAddThumbUp() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: `/v1/punch/record/thumbup/add`,
    data: {
      recordId: this.punchRecord.id,
    }
  }).then(() => {
    if (this.loadingCode !== loadingCode) return;
    if (this.punchRecord.commentInfo) {
      this.punchRecord.commentInfo.thumbUpAdded = true;
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (this.loadingCode !== loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (this.loadingCode !== loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doRemoveThumbUp() {
  if (this.loadingCode === this.busyLoadingCode) {
    this.$message.warning('操作频繁，请稍后再试');
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.$reqPost({
    path: `/v1/punch/record/thumbup/remove`,
    data: {
      recordId: this.punchRecord.id,
    }
  }).then(() => {
    if (this.loadingCode !== loadingCode) return;
    if (this.punchRecord.commentInfo) {
      this.punchRecord.commentInfo.thumbUpAdded = false;
    }
    this.busyLoadingCode = 0;
    doLoadCommentInfo.bind(this)();
  }).catch(() => {
    if (this.loadingCode !== loadingCode) return;
    this.$message.error('操作失败');
  }).complete(() => {
    if (this.loadingCode !== loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

export default {
  name: "PunchRecord",
  components: {Comment, YsButton, YsTextarea, YsImagePreview},
  mixins: [httpapi],
  props: {
    userId: Number,
    organizationId: Number,
    punchRecord: Object,
  },
  data() {
    return {
      loadingCode: 1,
      busyLoadingCode: 0,
      previewImageIndex: 0,
      previewImageVisible: false,
      commentInputVisible: false,
      commentMessageInputted: null,
    }
  },
  watch: {
    punchRecord: {
      handler() {
        this.loadingCode++;
      },
      immediate: true,
    }
  },
  methods: {
    handleImageClick(index) {
      this.previewImageIndex = index;
      this.previewImageVisible = true;
    },
    handleCommentClick() {
      let emitEvent = false;
      if (this.commentInputVisible == false) {
        emitEvent = true;
      }
      this.commentInputVisible = true;
      this.$nextTick(() => this.$refs.commentInput.focus());
      if (emitEvent) this.$emit('show:comment', {target: this});
    },
    handleDocumentClick(evt) {
      if (this.$el.contains(evt.target)) return;
      this.commentInputVisible = false;
    },
    addComment() {
      if (TextUtils.isBlank(this.commentMessageInputted)) {
        return;
      }
      doAddComment.bind(this)(this.commentMessageInputted.trim());
    },
    handleCommentDeleteClick(comment) {
      doDeleteComment.bind(this)(comment);
    },
    handleThumbUpClick() {
      if (this.punchRecord.commentInfo && this.punchRecord.commentInfo.thumbUpAdded) {
        doRemoveThumbUp.bind(this)();
      } else {
        doAddThumbUp.bind(this)();
      }
    },
    handleMoreCommentClick() {
      doLoadCommentListByPunchRecord.bind(this)();
    }
  },
  mounted() {
    document.addEventListener('click', this.handleDocumentClick);
  },
  destroyed() {
    document.removeEventListener('click', this.handleDocumentClick);
  }
}
</script>

<style scoped>

.punch-record__message {
  line-height: 1.5em;
}

.punch-record {
  background-color: white;
  padding-bottom: 40px;
}

.image-container, .image {
  width: 48px;
  height: 48px;
  filter: brightness(90%);
}

.image-container .image {
  display: block;
}

.image-container {
  overflow: hidden;
  margin-left: 4px;
  background-color: #bec0c0;
  cursor: pointer;
}

.image-container:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-left: 0px;
}

.image-container:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.image-container:hover {
  box-shadow: 0 0 16px #dadada;
}

.comment {
  padding: 4px 8px;
  border-radius: 100px;
  color: #686868;
}

.btn-thumbs-up, .btn-comment {
  cursor: pointer;
}

.btn-thumbs-up:hover, .btn-comment:hover {
  color: #3a8ee6;
}

.comment-item {
  padding: 8px 16px;
  border-radius: 8px;
  display: inline-block;
  background-color: #f1f1f1;
  max-width: 180px;
}

.comment-item-left__wrapper, .comment-item-right__wrapper {
  display: inline-block;
  cursor: pointer;
  color: #7d7c7c;
}

.comment-item-left__wrapper:hover, .comment-item-right__wrapper:hover {
  color: #3a8ee6;
}

.comment-item-left__wrapper:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 0px;
  border-right: 8px solid #f1f1f1;
  border-bottom: 0px;
  border-top: 10px solid transparent;
  display: inline-block;
  position: relative;
  top: 6px;
}

.comment-item-right__wrapper:after {
  content: "";
  width: 0;
  height: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 8px solid #f1f1f1;
  border-top: 10px solid transparent;
  display: inline-block;
  position: relative;
  top: 6px;
}

.comment-avatar__wrapper {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: bottom;
}

.comment-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  color: #bec0c0;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.btn-comment-delete {
  padding: 0px 8px;
  border-radius: 8px;
  border: 1px solid #dadada;
  vertical-align: top;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  visibility: hidden;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  color: #7d7c7c;
  line-height: 13px;
  font-size: 13px;
}

.btn-comment-delete:hover {
  background-color: #f091a6;
  border: 1px solid #f091a6;
  box-shadow: 0 0 4px #f091a6;
  color: white;
}

.comment-wrap:hover .btn-comment-delete {
  visibility: visible;
}

.image-mask__container {
  height: 0px;
}

.image-mask {
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, .3);
  position: relative;
  top: -48px;
}

.appendix-item {
  padding: 4px 6px;
  background-color: #f091a6;
  margin: 2px;
  border-radius: 100px;
  color: white;
}


</style>