<template>
  <div class="ys-timeline layout-horizontal layout-top">
    <div class="layout-inflexible" :style="`height: ${timelineHeight}px; overflow:hidden;`">
      <div class="layout-inflexible padding-left layout-vertical layout-center">
        <div class="dot dot-large"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
        <div class="dot dot-small"></div>
      </div>
    </div>
    <div class="margin-left-small layout-flexible" v-resize="handleContentResize">
      <div class="padding-vertical-small">
        <slot name="title">
          <div class="font-weight-bold">
            {{ title }}
          </div>
        </slot>
      </div>
      <div class="padding-right">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YsTimeline",
  props: {
    title: {
      type: String,
      default: '#时间#'
    }
  },
  data() {
    return {
      timelineHeight: 0,
    }
  },
  methods: {
    handleContentResize(evt) {
      this.timelineHeight = evt.height;
    }
  }
}
</script>

<style scoped>

.ys-timeline {
  background-color: white;
}

.dot {
  background-color: #f1f1f1;
  border-radius: 50%;
}

.dot-large {
  width: 16px;
  height: 16px;
  margin-top: 12px;
}

.dot-small {
  width: 8px;
  height: 8px;
  margin-top: 10px;
}
</style>