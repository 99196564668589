<template>
  <div class="punch-record-list">
    <!--<div style="height: 0px;" class="title layout-horizontal layout-right margin-right-large">
      <ys-date-picker @pick="handleDatePick">
        <div slot="reference" class="date-reference padding padding-small">{{ TimeUtils.format('yyyy-MM-dd', date) }} <i
            class="far fa-calendar-alt"></i></div>
      </ys-date-picker>
    </div>-->
    <div style="height: 100%">
      <ys-infinite-scroll-view ref="scrollbar"
                               class="scrollbar"
                               style="height: 100%;"
                               listen-reach-edge
                               @reach:top="handleListTopReached"
                               @reach:bottom="handleListBottomReached">
        <div class="layout-vertical" ref="punchRecordList" v-if="punchRecordList.length > 0">
          <div class="font-align-center font-color-placeholder font-size-extra-small" style="height: 2px;" v-if="loadAllBeforeRecords"></div>
          <div class="loading-container" v-if="isLoadingBefore">
            <div class="loading loading-before el-icon-loading"></div>
          </div>
          <div class="font-align-center font-color-light-placeholder padding padding-horizontal-large" style="background-color: white">-- 已经滑动到最前 --</div>
          <div v-for="punchRecord in punchRecordList" :key="punchRecord.id" ref="punchRecord" :data-id="punchRecord.id">
            <ys-timeline>
              <div slot="title" class="padding-right">
                <span>
                  <span class="font-weight-bold font-size-medium">{{ TimeUtils.formatNormal(punchRecord.time) }}</span>
                  <span class="margin-left-small font-color-placeholder">{{
                      punchRecord.punchTitle
                    }} · {{ punchRecord.punchItemTitle }}</span>
                </span>
              </div>
              <punch-record :punch-record="punchRecord" :user-id="userId"
                            :organization-id="organizationId"
                            @show:comment="handleCommentInputShow(punchRecord)"></punch-record>
            </ys-timeline>
          </div>
          <div class="font-align-center font-color-light-placeholder padding padding-horizontal-large" style="background-color: white">-- 已经滑动到末尾 --</div>
          <div class="loading-container" v-if="isLoadingAfter">
            <div class="loading loading-after el-icon-loading"></div>
          </div>
          <div class="font-align-center font-color-placeholder font-size-extra-small" style="height: 2px;" v-if="loadAllAfterRecords"></div>
        </div>
        <div class="padding font-align-center font-size-large font-color-placeholder margin-top-large" v-else>
          无打卡记录
        </div>
      </ys-infinite-scroll-view>
    </div>
  </div>
</template>

<script>
import PunchRecord from "@/pages/punch_record/basic/PunchRecord";
import YsTimeline from "@/components/wedigets/YsTimeline";
import {TimeUtils} from "@/assets/javascript/time-utils";
import httpapi from "@/assets/javascript/httpapi";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import YsButton from "@/components/wedigets/YsButton";
import YsDatePicker from "@/components/wedigets/YsDatePicker";

function doLoadPunchRecordListByDate(date) {
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  let path;
  let data;
  if (this.punchInstanceId) {
    path = '/punch/record/list/get/around_date';
    data = {
      instanceId: this.punchInstanceId,
    }
  } else {
    path = '/punch/record/list/all/get/around_date';
    data = {
      userId: this.userId,
      organizationId: this.organizationId,
    }
  }
  data.date = TimeUtils.format('yyyy-MM-dd', date);
  data.count = 10;
  this.$reqGet({
    path,
    data,
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    this.disableReachEdge = true;
    this.punchRecordList = res.data;
    this.scrollToDate();
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('')
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  })
}

function doLoadPunchRecordListBefore() {
  if (this.punchRecordList.length == 0) return;
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  this.isLoadingBefore = true;
  let path;
  let data;
  if (this.punchInstanceId) {
    path = '/punch/record/list/get/before';
    data = {
      instanceId: this.punchInstanceId,
    }
  } else {
    path = '/punch/record/list/all/get/before';
    data = {
      userId: this.userId,
      organizationId: this.organizationId,
    }
  }
  data.id = this.punchRecordList[0].id;
  data.count = 10;
  this.$reqGet({
    path,
    data,
  }).then(res => {
    if (this.loadingCode !== loadingCode) return;
    this.previousScrollTop = this.$refs.scrollbar.getScrollTop();
    let firstPunchRecordView = this.punchRecordList.length > 0 ? this.getPunchRecordViewById(this.punchRecordList[0].id) : null;
    if (firstPunchRecordView) {
      this.previousFirstPunchRecordId = this.punchRecordList[0].id;
      this.previousPunchRecordTop = firstPunchRecordView.getBoundingClientRect().top;
    }
    if (res.data.length == 0) {
      this.loadAllBeforeRecords = true;
    }

    this.$appendBefore(this.punchRecordList, res.data);

    this.scrollToLastPosition();
  }).catch(() => {
    if (this.loadingCode !== loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== loadingCode) return;
    setTimeout(() => this.isLoadingBefore = false, 500);
    this.busyLoadingCode = 0;
  })
}

function doLoadPunchRecordListAfter() {
  if (this.punchRecordList.length == 0) return;
  if (this.loadingCode === this.busyLoadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = loadingCode;
  let path;
  let data;
  this.isLoadingAfter = true;
  if (this.punchInstanceId) {
    path = '/punch/record/list/get/after';
    data = {
      instanceId: this.punchInstanceId,
    }
  } else {
    path = '/punch/record/list/all/get/after';
    data = {
      userId: this.userId,
      organizationId: this.organizationId,
    }
  }
  data.id = this.punchRecordList[this.punchRecordList.length - 1].id;
  data.count = 10;
  this.$reqGet({
    path,
    data,
  }).then(res => {
    if (this.loadingCode !== loadingCode) return;

    if (res.data.length == 0) {
      this.loadAllAfterRecords = true;
    }

    this.$appendAfter(this.punchRecordList, res.data);
  }).catch(() => {
    if (this.loadingCode !== loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== loadingCode) return;
    setTimeout(() => this.isLoadingAfter = false, 500);
    this.busyLoadingCode = 0;
  })
}


export default {
  name: "PunchRecordListPanel",
  mixins: [httpapi],
  components: {YsDatePicker, YsButton, YsInfiniteScrollView, YsTimeline, PunchRecord},
  props: {
    userId: Number,
    organizationId: Number,
    punchInstanceId: Number,
  },
  computed: {
    inputParams() {
      const {date, userId, organizationId, punchInstanceId} = this;
      return {
        date,
        userId,
        organizationId,
        punchInstanceId,
      }
    }
  },
  data() {
    return {
      TimeUtils,
      loadingCode: 1,
      date: TimeUtils.format('yyyy/MM/dd', new Date()),
      busyLoadingCode: 0,
      punchRecordList: [],
      disableReachEdge: false,
      isLoadingBefore: false,
      isLoadingAfter: false,

      previousScrollTop: 0,
      previousFirstPunchRecordId: null,
      previousPunchRecordTop: 0,

      loadAllBeforeRecords: false,
      loadAllAfterRecords: false,

    }
  },
  watch: {
    inputParams: {
      handler() {
        this.reloadAllData();
      },
      immediate: true,
    }
  },
  methods: {
    loadPunchRecordListByDate() {
      doLoadPunchRecordListByDate.bind(this)(TimeUtils.parseDate(this.date));
    },
    handleListTopReached() {
      if (this.disableReachEdge) return;
      doLoadPunchRecordListBefore.bind(this)();
    },
    handleListBottomReached() {
      if (this.disableReachEdge) return;
      doLoadPunchRecordListAfter.bind(this)();
    },
    handleDatePick(evt) {
      this.date = evt;
    },
    getPunchRecordViewById(id) {
      for (let punchRecordView of this.$refs.punchRecord) {
        if (punchRecordView.dataset.id == id) {
          return punchRecordView;
        }
      }
      return null;
    },
    scrollToLastPosition() {
      if (!this.previousFirstPunchRecordId) return;
      this.$nextTick(() => {
        let firstPreviousView = this.punchRecordList.length > 0 ? this.getPunchRecordViewById(this.previousFirstPunchRecordId) : null;
        if (firstPreviousView) {
          let scrollTop = this.$refs.scrollbar.getScrollTop() + firstPreviousView.getBoundingClientRect().top - this.previousPunchRecordTop;
          this.$refs.scrollbar.scrollTo(scrollTop);
        }
        this.previousFirstPunchRecordId = null;
      });
    },
    handleCommentInputShow(punchRecord) {
      setTimeout(() => {
        let punchRecordView = this.getPunchRecordViewById(punchRecord.id);
        if (!punchRecordView) return;
        let punchRecordRect = punchRecordView.getBoundingClientRect();
        let scrollBarRect = this.$refs.scrollbar.$el.getBoundingClientRect();
        if (punchRecordRect.bottom > scrollBarRect.bottom || punchRecordRect.height >= scrollBarRect.height) {
          let scrollToTop = punchRecordRect.bottom - this.$refs.punchRecordList.getBoundingClientRect().top - this.$refs.scrollbar.$el.clientHeight;
          this.$refs.scrollbar.smoothScrollTo(scrollToTop);
        } else if (punchRecordRect.top < scrollBarRect.top) {
          let scrollToTop = punchRecordRect.top - this.$refs.punchRecordList.getBoundingClientRect().top;
          this.$refs.scrollbar.smoothScrollTo(scrollToTop);
        }

      }, 300);
    },
    scrollToDate() {
      this.$nextTick(() => {
        let date = this.date.replaceAll('/', '-');
        let scrollToId = null;
        for (let n = this.punchRecordList.length - 1; n >= 0; n--) {
          if (date >= this.punchRecordList[n].date) {
            scrollToId = this.punchRecordList[n].id;
            break;
          }
        }

        if (scrollToId) {
          let scrollToTop = null;
          let scrollToView = this.getPunchRecordViewById(scrollToId);
          if (scrollToView) {
            scrollToTop = scrollToView.getBoundingClientRect().bottom - this.$refs.punchRecordList.getBoundingClientRect().top
                - this.$refs.scrollbar.$el.clientHeight;
          }
          if (scrollToTop != null) {
            this.$refs.scrollbar.smoothScrollTo(scrollToTop);
          }
        } else {
          this.$refs.scrollbar.smoothScrollTo(0);
        }
        setTimeout(() => this.disableReachEdge = false, 500);
      });
    },
    reloadAllData() {
      if (this.userId && this.organizationId) {
        this.loadingCode++;
        this.isLoadingBefore = false;
        this.isLoadingAfter = false;
        this.loadAllAfterRecords = false;
        this.loadAllBeforeRecords = false;
        this.punchRecordList = [];
        this.loadPunchRecordListByDate();
      }
    }
  },
  mounted() {

  },
  destroyed() {
  }
}
</script>

<style scoped>

.date-reference {
  width: 100%;
  background-color: rgba(255,255, 255, .5);
  backdrop-filter: blur(5px);
  box-shadow: 0 0 8px white;
  position: relative;
  z-index: 999;
}

.title {
  box-sizing: border-box;
  background-color: white;
}

.loading-container {
  height: 0px;
  text-align: center;
}

.loading {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  z-index: 9999;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px #bec0c0;
  left: calc(50% - 16px);
  color: #ff5500;
}

.loading.loading-before {
  top: 10px;
}

.loading.loading-after {
  top: -42px;
}

</style>